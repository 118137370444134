import request from 'network/request';

const baseURL = '/client/address/';

export function getUserAddress(params) {
  return request({
    url: baseURL + 'getAddress',
    method: 'get',
    params
  });
}
export function getUserAddressList() {
  return request({
    url: baseURL + 'getAddressList',
    method: 'post'
  });
}
export function createGoodsAddress(params) {
  return request({
    url: baseURL + 'addOrPutAddress',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function deleteGoodsAddress(params) {
  return request({
    url: baseURL + 'delAddress',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function getShoppingList(params) {
  return request({
    url: '/order/extend/po/list',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function getShoppingDetail(params) {
  return request({
    url: '/order/extend/po/detail',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
