<template>
  <section class="create_goods_address">
    <el-dialog :visible="visible" width="30%" @close="cancelClick">
      <template slot="title">
        <div class="title">{{title}}</div>
      </template>

      <el-form ref="form" :model="formData" :rules="formRules" label-width="150px">
        <el-form-item label="收货人姓名" prop="linkMan">
          <el-input class="input300" type="text" v-model="formData.linkMan" placeholder="请输入收货人姓名"></el-input>
        </el-form-item>
        <el-form-item label="收货人手机" prop="linkTel">
          <el-input class="input300" type="text" v-model="formData.linkTel" placeholder="请输入收货人手机"></el-input>
        </el-form-item>
        <el-form-item label="请选择省" prop="province">
          <el-select class="select150" v-model="province" placeholder="请选择" @change="chooseProvince">
            <el-option v-for="(item, index) in provinceOptions" :key="index" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="请选择市" prop="city">
          <el-select class="select150" v-model="city" placeholder="请选择" @change="chooseCity">
            <el-option v-for="(item, index) in cityOptions" :key="index" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="请选择区/县" prop="area">
          <el-select class="select150" v-model="area" placeholder="请选择" @change="chooseArea">
            <el-option v-for="(item, index) in areaOptions" :key="index" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址/门牌" prop="addressDesc">
          <el-input class="input300" type="textarea" v-model="formData.addressDesc" :rows="3"></el-input>
        </el-form-item>
        <el-form-item label="是否设为默认" prop="isDefault">
          <el-radio-group v-model="formData.isDefault">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <template slot="footer">
        <el-button type="primary" @click="determineClick">确定</el-button>
        <el-button @click="cancelClick">取消</el-button>
      </template>
    </el-dialog>
  </section>
</template>

<script>
  import {address} from 'utils/address.js';
  import {
    getUserAddress,
    createGoodsAddress
  } from 'api/settlementPage.js';

  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        provinceOptions: address.filter(item => !item.parent),
        cityOptions: [],
        areaOptions: [],
        province: '',
        city: '',
        area: '',
        formData: {
          linkMan: '',
          linkTel: '',
          province: '',
          city: '',
          area: '',
          addressDesc: '',
          isDefault: 1
        },
        formRules: {
          linkMan: [{required: true, message: '请输入', trigger: 'blur'}],
          linkTel: [{required: true, message: '请输入', trigger: 'blur'}],
          province: [{required: true, message: '请选择', trigger: 'blur'}],
          city: [{required: true, message: '请选择', trigger: 'blur'}],
          area: [{required: true, message: '请选择', trigger: 'blur'}],
          addressDesc: [{required: true, message: '请输入', trigger: 'blur'}],
          isDefault: [{required: true, message: '请选择', trigger: 'blur'}]
        }
      }
    },

    methods: {
      getGoodsAddress(id) {
        getUserAddress({
          addressId: id
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.formData = res.data;
          this.province = address.filter(item => item.name == this.formData.province)[0].value;
          this.cityOptions = address.filter(item => item.parent == this.province);
          this.city = address.filter(item => item.name == this.formData.city)[0].value;
          this.areaOptions = address.filter(item => item.parent == this.city);
          this.area = address.filter(item => (item.parent == this.city) && (item.name == this.formData.area))[0].value;
        });
      },
      chooseProvince(province) {
        this.cityOptions = address.filter(item => item.parent == province);
        this.formData.province = this.provinceOptions.filter(item => item.value == province)[0].name;
      },
      chooseCity(city) {
        this.areaOptions = address.filter(item => item.parent == city);
        this.formData.city = this.cityOptions.filter(item => item.value == city)[0].name;
      },
      chooseArea(area) {
        this.formData.area = this.areaOptions.filter(item => item.value == area)[0].name;
      },
      determineClick() {
        this.$refs.form.validate(valid => {
          if(!valid) {
            return false;
          }
          createGoodsAddress(this.formData).then(res => {
            if(res.code) {
              this.$message.error(res.message);
              return;
            }
            this.$message.success(res.message);
            this.$emit('determine');
            this.cancelClick();
          });
        });
      },
      cancelClick() {
        this.$emit('update:visible', false);
        this.formData = this.$options.data().formData;
        this.province = '';
        this.city = '';
        this.area = '';
      }
    }
  }
</script>

<style lang="less" scoped>
  .create_goods_address {
    .title {
      margin: 15px 0 0 15px;
      font-size: var(--fontSize20);
      color: var(--color000);
      font-weight: bold;
    }
  }
</style>
